.MuiMenuItem-root {
    &.menu-item-element {
        display: flex;
        cursor: default;
        padding-bottom: 0px;
        &:hover {
            background-color: white;
        }
        .MuiFormControlLabel-root {
            width: 100%;
        }
        .MuiFormControlLabel-label {
            font-size: 0.8rem;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 1rem;
            width: 100%;
        }
        .MuiSvgIcon-root {
            font-size: 0.9rem;
        }
    }
    &.menu-action-buttons {
        display: flex;
        justify-content: flex-end;
        cursor: default;
        &:hover {
            background-color: white;
        }
    }
}
