@import 'styles/conf/_colors.scss';

.chart-settings-drawer {
    & > div {
        background-color: initial;
    }

    .chart-settings-paper {
        max-width: 40%;
        background-color: #fff;
        box-shadow: none;

        &:focus {
            outline: none;
        }

        .chart-settings-form {
            width: 100%;
        }
    }
}

.chart-settings-form {
    width: 100%;
}
.chart-settings-expansion-panel {
    box-shadow: none !important;
    margin-top: 0 !important;
}

.chart-settings-expansion-header,
.chart-settings-expansion-details,
.chart-settings-expansion-panel-details {
    background-color: #efefef !important;
}

// these are the drawer's dynamic borders,
// as when two closed drawers are following we only need one border.
.chart-settings-expansion-header:before {
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
    opacity: 1;
    position: absolute;
    transition:
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: lightgrey;
}

.chart-settings-expansion-panel:last-of-type
    .chart-settings-expansion-header:after {
    bottom: 1px;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
    opacity: 1;
    position: absolute;
    transition:
        opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: lightgrey;
}
