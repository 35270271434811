.grid-chart-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 0px;
    padding: 0px;
    padding-right: 1rem;
    position: absolute;
    bottom: 0px;
}

.show-comment-button-hide {
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s,
        opacity 0.5s linear;
    position: absolute;
    bottom: 0px;
    right: 4px;
    z-index: 2;
}
