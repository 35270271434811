// Slightly lighten a color
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}
/// Slightly darken a color
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}

$body-bg: #ffffff;
$body-bg-dark: #fafafa;
$default-text: #3f4750;
$card-bg: #efefef;
$border: #d6d6d6;
$border-dark: shade($border, 15%);
$primary: #003cac !default;
$primary-bg: tint($primary, 90%);
$secondary: #4cb5fc !default;
$tertiary: #e5a503 !default;
$info: #2dacd1 !default;
$success: #90b900 !default;
$warning: #dfb81c !default;
$danger: #e85656 !default;
