@import 'styles/conf/_colors.scss';

.destinations-paper {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;
    width: 100%;

    .drawer-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .drawer-header-left,
        .drawer-header-right {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .drawer-header-right {
            padding-right: 24px;
        }
    }

    & > div:nth-child(2) {
        flex: 1;
    }

    .new-destination-form {
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.add-child-rows {
    display: flex;
    flex-direction: column;
}
