@import 'styles/conf/variables';

.add-grid-chart {
    height: 40%;
    width: 50%;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 2%; // same margin than the charts
    margin-bottom: 5rem;

    border: solid 1px #efefef;
    box-shadow: none !important;
    filter: drop-shadow(0 100px 50px #eee);
    transition: filter 300ms ease;
    &:hover {
        transition: filter 300ms ease;
        filter: drop-shadow(0 100px 50px #aaa);
    }

    .add-grid-chart-plus {
        margin: 1rem;
    }
    @media only screen and (max-width: 640px) {
        width: 80%;
    }
}
