@import 'styles/conf/variables';

.error-boundary {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
    button {
        margin-top: 12px;
    }
}
