@import 'styles/conf/_colors.scss';
@import 'styles/conf/_variables.scss';

.cellar-page-container {
    background-color: #efefef;
    flex: 1;
    overflow-x: hidden;
    height: 100%;

    .header {
        background-color: $body-bg;
        margin-bottom: 2rem;

        h1 {
            margin-left: 1rem;
        }
    }

    .cellar-container {
        margin-top: 2rem;
        flex-grow: 1;
        background-color: $body-bg;
        height: calc(100% - 2rem);
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }

        // Contains the left side of the drawer
        .content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-x: auto; // this is important for the import csv workflow
            background-color: $body-bg;

            .manty-loader {
                margin: auto 0;
            }

            .table-description {
                padding-top: 24px;
                padding-left: 24px;
                padding-right: 24px;
            }

            .table-description {
                overflow: auto;

                .breadcrumbs-link {
                    padding-right: 0;
                }

                h2 {
                    margin-left: 0;
                }

                .number-of-lines {
                    display: flex;
                    margin-top: 1em;
                    margin-bottom: 1em;
                }

                .relation {
                    display: flex;

                    .rawLink {
                        text-decoration: underline;
                        margin-left: 2px;
                        cursor: pointer;
                        margin-top: 5px;
                    }
                }
            }

            .header-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 2rem;
            }

            .column-name {
                margin-bottom: 1rem;

                h3 {
                    font-size: 12px;
                    color: #899fb1;
                }
            }

            .domain-description {
                width: 100%;

                h2 {
                    margin: 2rem;
                }

                .domain-header-wrapper {
                    margin-top: 1rem;
                    margin-bottom: 2rem;
                    height: 2.5rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .title {
                        margin: 0;
                    }
                }

                .title {
                    margin-top: 0;
                    margin-left: 0.5rem;
                }
            }

            .search-bar-wrapper {
                padding-left: 2rem;
                width: clamp(18rem, 45%, 27rem);
            }

            .tables-container {
                width: 100%;
                max-width: 1000px;
                margin-bottom: 3rem;

                .table-icon {
                    margin: 0.4rem;
                    margin-left: 1rem;
                }

                .table-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }

                .table-name {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    height: 70%;

                    h3 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-right: 1rem;
                        width: 100%;
                    }
                }

                .table-value {
                    flex: 1;
                }

                .table-subdomain {
                    flex: 1;
                    display: flex;
                    margin-left: 3rem;
                }

                .column-name {
                    margin-bottom: 1em;
                    flex: 1;
                    justify-content: left;
                    align-items: left;
                }
            }

            .integration-parameters-container {
                margin: 3rem;

                .parameters-header {
                    margin: 2rem;
                    margin-bottom: 3rem;
                }

                .parameters-container {
                    margin-left: 2.5rem;

                    .parameter-row {
                        display: flex;
                        padding: 0;

                        .column-name {
                            h3 {
                                font-size: 14px;
                            }
                        }

                        .parameter-name,
                        .parameter-value,
                        .parameter-example {
                            flex: 1;
                        }

                        .parameter-name {
                            margin-right: 1rem;
                            white-space: nowrap;

                            h3 {
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .MuiInputBase-input,
                        .MuiChip-label {
                            font-size: 14px;
                            color: $default-text;
                        }

                        .MuiInputBase-input {
                            padding: 0.3rem;
                        }

                        .MuiInputBase-root {
                            padding-top: 0em;
                            padding-bottom: 0em;
                        }

                        .example-value {
                            color: #899fb1;
                            font-size: 14px;
                        }
                    }
                }
            }

            .lines-container {
                margin-left: 2rem;
            }

            .card-container {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                margin-top: 3rem;

                @media screen and (max-width: 640px) {
                    justify-content: center;
                }

                .cellar-card {
                    margin: 0 1.5rem 1.5rem 0;
                    width: 17rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .padded {
                        margin-left: 10px;
                    }

                    .cellar-card-actions {
                        display: flex;
                        justify-content: space-between;
                    }

                    .card-header {
                        display: flex;
                        margin-right: 1rem;
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                        flex-direction: row;
                        justify-content: space-between;

                        .calculated {
                            color: 'white';
                            padding: 0.1rem 0.6rem;
                            display: inline-block;
                            border-radius: 0.3rem;
                            margin-right: 0.5rem;
                            box-shadow: #ccc 0px 1px 7px;
                        }
                    }
                }

                .cellar-card-loading {
                    min-height: 12rem;
                }

                .cellar-card-hidden {
                    color: #808080;
                }
            }
        }
    }
}
