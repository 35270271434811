.form-buttons {
    padding-top: 12px;
    display: flex;
    justify-content: right;
    align-items: center;

    // avoid resizing when the loading replace the buttons
    min-height: 54px;

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
}

// fix text within TextField
.MuiInputLabel-outlined.MuiInputLabel-marginDense {
    width: 100%;
}
