.grid-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    width: 100%;
    height: 100%;

    &.right {
        align-items: flex-end;
    }

    &.left {
        align-items: flex-start;
    }

    .dx-master-detail-cell {
        padding: 10px !important;
    }
}
