.dependent {
    padding-left: 40px;
    margin-top: -20px;
}

.shareform {
    //override default modal sizing
    .MuiDialog-paperWidthSm {
        max-width: 1100px;
        max-height: 600px;
        width: 100%;
        height: 100%;
    }

    .MuiDivider-root {
        background-color: white;
    }

    li {
        // avoid MUI 100% width that add a scrollbar
        width: inherit !important;
    }
}
