.dashboard-new-tile-paper {
    position: relative;
    display: flex;
    flex-direction: column;

    width: 16.5rem;
    height: 14.25rem;

    .dashboard-tile-title {
        display: flex;
        align-items: center;
        padding: 1rem;

        flex-shrink: 0;

        svg {
            margin-right: 0.4rem;
        }
    }

    .dashboard-tile-image {
        margin: 1rem;
        margin-top: 0.5rem;
        box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.13);
    }
}
