.logo-print {
    img {
        height: 2cm;
        margin: 0cm 0.5cm 0.5cm 0.5cm;
    }

    .manty-logo-print {
        float: right;
    }
}

.printable-root {
    width: 21cm;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1300;
    background: white;
    // to enable the background color in print mode
    // see https://stackoverflow.com/questions/3893986/css-media-print-issues-with-background-color
    // useful for separators and the filter bar, for instance
    -webkit-print-color-adjust: exact;

    .printable-page {
        // NOTE: zoom is a non standard feature
        // we allow ourselves to use it because printing will only occur in Chrome run by playwright
        // BEWARE you need to update NB_RGL_UNIT_PER_PRINT_PAGE if you change the zoom
        zoom: 0.6;
        // NOTE: here we updated from 49.5 to 49.35 to match new playwright chromium version
        // default scaling in PDF mode
        height: 49.35cm; // =A4 height/zoom (=29.6/0.X)

        display: block;
        padding-left: 0.3cm;
        position: relative;
        z-index: 0; // should not be necessary, but it creates a neutral stacking context to avoid widgets overlapping other pages
        // hide overflow to not impact following pages.
        overflow: hidden;
        .printable-grid-element {
            padding-right: 0.3cm;
            & > div {
                width: 100%;
                height: 100%;
            }
        }
        @media screen {
            // display page borders in screen mode only, for debugging
            border: solid black;
        }
    }
}

// remove default print title, date and url
// see https://stackoverflow.com/questions/2573603/removing-page-title-and-date-when-printing-web-page-with-css
@page {
    size: auto;
    margin: 0mm;
}
