.rich-snack-message {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    .icon-and-text-container {
        display: flex;
        align-items: center;
    }

    .icon {
        display: flex;
    }
}

.MuiSnackbarContent-message {
    width: 100%;
}
