.modal-form {
    .form-body {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .form-buttons {
        margin: 0.5rem;
        padding-top: 0;
    }

    .form-actions {
        margin-top: 1.5rem;
    }

    .form-text {
        padding: 1rem 0 0 0;
        margin: 0;
    }
}
