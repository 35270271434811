.explore-viz {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .chart-draw-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px;
    }
    @media screen and (max-width: 640px) {
        .chart-draw-container {
            padding: 0;
        }
    }
}
