@import 'styles/conf/_colors.scss';

.user-list {
    .ag-close-cell:hover svg {
        fill: $danger;
    }
    .ag-cell-inline-editing {
        height: var(--ag-row-height);
        .ag-input-field-input,
        input[class^='ag-'][type='text'] {
            padding-bottom: 1px;
        }
    }

    .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
        border-width: 2px;
        background-color: var(--ag-subheader-background-color);
    }
}
