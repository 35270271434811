.table-chart {
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;

    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: #f2f2f2;
            }

            &:hover {
                background-color: #e5ebf6;
            }
            > td {
                line-height: inherit;
            }
        }
    }
}

.ag-header-cell-text {
    text-overflow: clip !important;
    overflow: visible !important;
    white-space: normal !important;
}
