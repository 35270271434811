.budget-charts-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;

    background: #ffffff;
    border-radius: 4px;
}

.budget-chart {
    display: flex; //super important use flex for Autoflexer
    flex-flow: column wrap;
    width: fit-content;
    max-width: 45%;
    height: 70%; //do not remove

    &.summary {
        width: 50%;
    }

    &.phase-dependent {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-end;
        align-items: center;
    }

    &.first {
        width: 20%;
        max-width: 25%;
    }

    &.second {
        max-width: 100%;
        width: 70%;
    }
}

.budget-chart-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 2vh;
}
