.dashboard-group {
    margin: 0.7rem 0;

    .small-icon {
        padding: 6px;
    }
    .very-small-icon {
        padding: 3px;
    }

    &.extra-margin {
        margin-bottom: 1.7rem;
    }

    .dashboard-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 0.5rem;
        background-color: #f7f7f7;
        box-shadow: 0px 2px 2px #eee;
        border-radius: 0.5rem;

        transition: background-color 150ms ease;
        &:hover {
            background-color: #eeeeee;
            .expand-icon {
                background-color: #dddddd;
            }
        }
        .expand-icon {
            transition: background-color 300ms ease;
            background-color: #eeeeee;
            &:hover {
                background-color: #d0d0d0;
            }
        }
        .selection-checkbox {
            height: 32px;
            width: 32px;
        }
        .rotated {
            transform: rotateZ(-90deg);
        }

        .dashboard-title {
            flex-grow: 1;
            padding: 0.4rem 1rem 0.3rem 0.5rem;
        }

        .dashboard-item-icons {
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }
    }
}
