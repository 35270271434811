@import 'styles/conf/_colors.scss';

.dashboard-loader {
    flex: auto;
}

.dashboard-body {
    justify-content: center;

    .empty-dashboard-text {
        text-align: center;
    }

    .react-grid-layout {
        flex: 1;
        float: none;

        // box-shadow does not work as expected when we have transform css property
        // like we do here for the graph grid positioning
        filter: drop-shadow(0 100px 50px #eee);
        @media print {
            display: block;
            filter: drop-shadow(0 0 0 #fff);
        }
    }
}

.edit-mode {
    background: radial-gradient(darken($body-bg-dark, 10%) 10%, transparent 11%)
        8px 8px;
    background-size: 10px 10px;
    background-color: $body-bg-dark;
}
