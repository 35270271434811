@import 'styles/conf/colors';
@import 'styles/conf/variables';

.chart-padding {
    padding: 6px;
}

.table-chart-padding {
    padding: 6px 6px 40px 6px;
}

.chart {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    .area-chart,
    .bar-chart,
    .line-chart,
    .pie-chart,
    .scatterplot-chart,
    .treemap-chart {
        path {
            fill: none;
        }
    }

    .gauge-chart {
        text {
            fill: $default-text;
            font-weight: bold;
            text-transform: uppercase;
        }

        .chart-filled {
            fill: $primary;
        }

        .chart-empty {
            fill: #dedede;
        }

        .gauge-max-value,
        .gauge-min-value {
            font-size: 1.5em;
        }
    }

    .number-chart {
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem;

        h1 {
            font-size: 5em;
        }

        h2 {
            font-size: 2em;
            text-transform: uppercase;
            line-height: 1em;
            margin: 0;
        }

        .number-chart-legend {
            padding: 0 30%;
        }
    }

    .pie-chart {
        & > div {
            display: flex;
            justify-content: center;
        }
    }

    .mapboxgl-missing-css {
        display: none;
    }
}
