@import 'styles/conf/_variables.scss';

.vision-layout {
    height: 100%;
    display: flex;
    flex-flow: row;

    @media print {
        // required to show the content instead of scrolling bars
        display: block;
    }

    .page-container {
        flex: 1;
        overflow-x: hidden;
        @media print {
            margin-left: 0;
        }
    }
}
