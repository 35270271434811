@import 'styles/conf/_colors.scss';

.grid-chart {
    height: 100%;
    display: flex;
    flex-flow: column;

    &.editable-grid {
        .grid-chart-header {
            cursor: grab;

            &:active {
                cursor: grabbing;
            }
        }
    }

    .grid-chart-header {
        min-height: 48px;
        display: flex;
        overflow: hidden;
        align-items: center;
        padding: 0 1rem;

        .chart-title {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            flex-grow: 1;
            word-break: break-word;

            cursor: pointer;
            transition: text-decoration 150ms ease;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .grid-chart-card {
        width: 100%;
        padding: 0;
        overflow: visible; // necessary for tooltips

        flex: 1;

        display: flex;
        flex-direction: column;

        border: solid 1px #efefef;
        box-shadow: none;
    }

    .grid-chart-bottom {
        min-height: 30px;
        width: 100%;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;

        .chart-bottom-time-filter {
            visibility: hidden;
            opacity: 0;
            transition:
                visibility 0s,
                opacity 0.5s linear;
        }
    }

    &:hover {
        .show-comment-button-hide {
            visibility: visible;
            opacity: 1;
        }

        .chart-bottom-time-filter {
            visibility: visible;
            opacity: 1;
        }
    }

    .media-card {
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.react-grid-item.react-grid-placeholder {
    background: $primary !important;
}

.react-grid-item {
    // BEWARE: this is important for the lazy loading to work on charts!
    // without this, all the charts are considered in-view when created because
    // their initial position is (0,0) hence visible.
    transition: none !important;

    &:hover .echarts-for-react .comment-section {
        z-index: 2; // Used to avoid tooltips being hidden by other cards and to diplay comments above charts
    }

    & > .react-resizable-handle {
        z-index: 1;
        bottom: -4px;
        right: -4px;
    }
}
