.support-wrapper {
    padding: 2vh 2vw;
    width: 100%;
    overflow-y: auto;

    .support-feature-wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 3vh 0vw;

        .all-actions-wrapper {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .selectors-wrapper {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-evenly;
                align-items: flex-start;

                .selector-wrapper {
                    width: 13vw;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin: 1rem 0.5rem;
                }

                .istemplate-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-self: flex-end;
                    margin: 1rem 0.5rem;
                }
            }
        }
    }
}
