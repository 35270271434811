@import 'styles/conf/colors';
@import 'styles/conf/variables';

.comment-section {
    overflow-y: auto;
    width: calc(100% - 1px);
    position: absolute;
    bottom: 50px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;

    .chart-comment {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .comment-author {
            margin-bottom: 6px;
        }

        .comment-content {
            margin-bottom: 6px;
            flex: 1;
        }

        .comment-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .comment-date {
                color: tint($default-text, 30%);
            }

            .comment-delete {
                color: tint($danger, 30%);
                cursor: pointer;
            }
        }
    }

    .comment-text-field {
        position: sticky;
        bottom: 0;
        width: 100%;
        padding-left: 12px;
        padding-right: 6px;
        padding-top: 10px;
        background-color: white;
        overflow: hidden;
        min-height: 50px;

        .add-comment-button {
            position: relative;
            left: 6px;
        }
    }
}
