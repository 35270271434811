.echart-helper-tooltips {
    max-height: 50vh;
    overflow-y: hidden;

    // Add a shadow at the bottom of the tooltip if it overflow.
    background:
        linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(
            farthest-side at 50% 0,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0)
        ),
        radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
            )
            0 100%;

    background-repeat: no-repeat;
    background-color: white;
    background-size:
        100% 40px,
        100% 40px,
        100% 14px,
        100% 14px;
    background-attachment: local, local, scroll, scroll;
}
