@import 'styles/conf/_colors.scss';
@import 'styles/conf/_variables.scss';

.query-perimeter {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #efefef;
    padding-left: 12px;

    .filters-wrapper {
        flex: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        overflow-x: auto;
        padding: 8px 0px;

        .perimeter-filter {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 4px 8px;
            width: fit-content;
            flex-shrink: 0;
            @media only screen and (min-width: 640px) {
                max-width: 50vw;
            }
            @media only screen and (min-width: 1080px) {
                max-width: 40vw;
            }

            .filter-icons {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-around;
                align-items: center;
                max-height: 5vh;

                .icon-button-filter {
                    padding: 0.5vh;
                }
            }
        }
    }
}
