@import 'styles/conf/variables';

.dx-viewport.pivot-table {
    width: 100%;
}

.budget-loader {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.budget-proposal-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    background-color: #efefef;
}
