.mappings-table {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin: 0 1.5rem;

    .filter-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    .filter-right {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
    }
}
