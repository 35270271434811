@import 'styles/conf/_colors.scss';

.dashboard-list-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $default-text;

    svg {
        margin-right: 6px;
        fill: $default-text;
    }
}
